var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "proposal-documents" },
    [
      _c(
        "div",
        { staticClass: "m-1 mr-4 px-0" },
        [
          _c("breadcrumb", {
            attrs: { title: "Documentos", actualPage: "Lista de documentos" },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "vx-row" }, [
        _vm.profileHelpers.checkPermission([
          _vm.profileHelpers.menuFuncionalitiesEnum.GERENCIAR_DOCUMENTOS,
        ])
          ? _c(
              "div",
              { staticClass: "vx-col pt-5 text-right w-full mt-2" },
              [
                _c(
                  "vs-button",
                  {
                    attrs: { color: "primary" },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.openDialogUpload()
                      },
                    },
                  },
                  [
                    _c("span", { staticClass: "font-semibold pr-2 pl-2" }, [
                      _vm._v("\n          Upload de documento\n        "),
                    ]),
                  ]
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
      _c(
        "vs-table",
        {
          staticClass: "mt-10 table-available",
          attrs: {
            data: _vm.RequestDocuments,
            noDataText: "Nenhum arquivo foi cadastrado",
          },
        },
        [
          _c(
            "template",
            { slot: "thead" },
            [
              _c("vs-th", [_vm._v("\n        Nome\n      ")]),
              _c("vs-th", [_vm._v("\n        Data de inserção\n      ")]),
              _c("vs-th", [_vm._v("\n        Ações\n      ")]),
            ],
            1
          ),
          _vm._l(_vm.RequestDocuments, function (document, indextr) {
            return _c(
              "vs-tr",
              { key: indextr, attrs: { data: document } },
              [
                _c("vs-td", [_vm._v(_vm._s(document.Name))]),
                _c("vs-td", [
                  _vm._v(
                    _vm._s(
                      _vm._f("moment")(document.CreateDate, "DD/MM/YYYY HH:mm")
                    )
                  ),
                ]),
                _c(
                  "vs-td",
                  [
                    _c(
                      "vs-button",
                      {
                        staticClass: "p-0",
                        attrs: {
                          color: "dark",
                          type: "flat",
                          title: "Download do documento",
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.downloadDocument(document.UniqueId)
                          },
                        },
                      },
                      [_c("i", { staticClass: "onpoint-download-simple" })]
                    ),
                    _vm.profileHelpers.checkPermission([
                      _vm.profileHelpers.menuFuncionalitiesEnum
                        .GERENCIAR_DOCUMENTOS,
                    ])
                      ? _c(
                          "vs-button",
                          {
                            staticClass: "p-0",
                            attrs: {
                              color: "dark",
                              type: "flat",
                              title: "Editar nome do documento",
                            },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.openDialogEditDocument(
                                  document.UniqueId
                                )
                              },
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "onpoint-pencil-simple-line",
                            }),
                          ]
                        )
                      : _vm._e(),
                    _vm.profileHelpers.checkPermission([
                      _vm.profileHelpers.menuFuncionalitiesEnum
                        .GERENCIAR_DOCUMENTOS,
                    ])
                      ? _c(
                          "vs-button",
                          {
                            staticClass: "p-0",
                            attrs: {
                              color: "dark",
                              type: "flat",
                              title: "Excluir documento",
                            },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.openDialogDeleteDocument(
                                  document.UniqueId,
                                  document.Name
                                )
                              },
                            },
                          },
                          [_c("i", { staticClass: "onpoint-trash" })]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            )
          }),
        ],
        2
      ),
      _c(
        "div",
        {
          staticClass: "download-all mt-1 mr-5",
          on: {
            click: function ($event) {
              return _vm.downloadAllDocuments()
            },
          },
        },
        [
          _c("span", [_vm._v("Baixar todos")]),
          _c("i", { staticClass: "onpoint-download" }),
        ]
      ),
      _vm.openDialogAddDocumente
        ? _c("base64Upload", {
            attrs: {
              modalUpload: _vm.openDialogAddDocumente,
              documentoName: "",
              insertName: true,
            },
            on: {
              callBackUpload: _vm.callBackUpload,
              closeModal: _vm.closeModal,
            },
          })
        : _vm._e(),
      _c(
        "vs-popup",
        {
          staticClass: "popup-edit-documents z-index-modal",
          attrs: { title: "Editar nome", active: _vm.openEditDocument },
          on: {
            "update:active": function ($event) {
              _vm.openEditDocument = $event
            },
          },
        },
        [
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col w-full" },
              [
                _c("p", { staticClass: "flex font-semibold" }, [
                  _vm._v("Informe o novo nome"),
                ]),
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: { size: "large" },
                  model: {
                    value: _vm.newDocumentName,
                    callback: function ($$v) {
                      _vm.newDocumentName = $$v
                    },
                    expression: "newDocumentName",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "vx-row flex justify-end mt-5" }, [
            _c(
              "div",
              [
                _c(
                  "vs-button",
                  {
                    attrs: { color: "primary" },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.editDocument()
                      },
                    },
                  },
                  [
                    _c("span", { staticClass: "font-semibold pr-2 pl-2" }, [
                      _vm._v("\n            Confirmar\n          "),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _c(
        "vs-popup",
        {
          staticClass: "popup-delete-documents z-index-modal",
          attrs: { title: "Deletar arquivo", active: _vm.openDeleteDocument },
          on: {
            "update:active": function ($event) {
              _vm.openDeleteDocument = $event
            },
          },
        },
        [
          _c("div", { staticClass: "vx-row flex justify-center" }, [
            _c("div", [
              _c("h4", [_vm._v("Confirma a ação de deleter o documento?")]),
            ]),
          ]),
          _c("div", { staticClass: "vx-row flex justify-center mt-5" }, [
            _c("div", { staticClass: "document-name" }, [
              _vm._v(_vm._s(_vm.documentName)),
            ]),
          ]),
          _c("div", { staticClass: "vx-row flex justify-center mt-5" }, [
            _c(
              "div",
              [
                _c(
                  "vs-button",
                  {
                    staticClass: "mr-10",
                    attrs: { color: "dark" },
                    nativeOn: {
                      click: function ($event) {
                        _vm.openDeleteDocument = false
                      },
                    },
                  },
                  [
                    _c("span", { staticClass: "font-semibold pr-2 pl-2" }, [
                      _vm._v("\n            Voltar\n          "),
                    ]),
                  ]
                ),
                _c(
                  "vs-button",
                  {
                    attrs: { color: "dark" },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.deleteDocument(_vm.RequestDocumentUniqueId)
                      },
                    },
                  },
                  [
                    _c("span", { staticClass: "font-semibold pr-2 pl-2" }, [
                      _vm._v("\n            Confirmar\n          "),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }